import React from 'react';
import { ApiKeyContext, BrandConfigurationContext } from '../../index';
import { FORM_STEPS_ENUM, FormDataType, formDefaultValues } from './constants';
import { Stepper, StepperMob } from '../UI/Stepper';
import { Text } from '../UI/Text';
import './RepairBookingForm.scss';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { messages } from '../../Messages';
import { formatFormArgs, getFormStepsNames, getFormStepKeys } from './utils';
import { createOrder, createStripePaymentIntent } from '../../utils/api';
import uploadFileToBlob from '../../blobConfig';
import { PageLoader } from '../UI/PageLoader';
import { Error } from '../../pages/error';
import { BrandContext } from '../../App';

const FormFooter = React.lazy(() => import('./FormFooter/index'));

const ContactDetails = React.lazy(() => import('./ContactDetails/index'));
const WarrantyCheck = React.lazy(() => import('./WarrantyCheck/index'));
const DocumentationCheck = React.lazy(() => import('./DocumentationCheck/index'));
const ProductDetails = React.lazy(() => import('./ProductDetails/index'));
const DamageDetails = React.lazy(() => import('./DamageDetails/index'));
const PickUpDetails = React.lazy(() => import('./PickUpDetails/index'));
const Summary = React.lazy(() => import('./Summary/index'));

function useForceUpdate() {
  const [, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
}

export const RepairBookingForm: React.FC = () => {
  const [productForm, setProductForm] = React.useState('info');
  const [contactForm, setContactForm] = React.useState('info');
  let brandConfiguration = React.useContext(BrandConfigurationContext);
  const brandDetails = React.useContext(BrandContext);
  let list = getFormStepsNames('repair', brandConfiguration);
  let keysList: any = getFormStepKeys('repair', brandConfiguration);
  const [formSteps, setFormSteps] = React.useState(list);
  const [formStepsKeys, setFormStepsKeys] = React.useState(keysList);
  const [activeStep, setActiveStep] = React.useState(list[0]);
  const [activeSchema, setActiveSchema] = React.useState(schema(activeStep));
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  let apiKey = React.useContext(ApiKeyContext);
  const location = useLocation();
  const lineItemDetails = location.state?.lineItemDetails;

  const [errorOnSubmission, setErrorOnSubmission] = React.useState('');

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormDataType>({
    resolver: yupResolver(activeSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { ...formDefaultValues },
  });

  const updateLocalStorage = (value: string, field: any) => {
    const existingData = localStorage.getItem('formData');
    let formData = existingData ? JSON.parse(existingData) : {};
    if (formData?.serviceType.length < 1) formData['serviceType'] = 'repair';

    if (field === 'isLabelsMissing' || field === 'receiptAvailable' || field === 'returnProduct') {
      let value = getValues(field);
      formData[field] = !value;
    } else {
      formData[field] = value;
    }
    localStorage.setItem('formData', JSON.stringify(formData));
  };

  const handleChange = React.useCallback(
    (value: string, field: any) => {
      if (field === 'isLabelsMissing' || field === 'receiptAvailable' || field === 'returnProduct') {
        let value = getValues(field);
        setValue(field, !value);
      } else {
        setValue(field, value);
      }
      if (brandConfiguration.defaultServiceType) {
        setValue('serviceType', brandConfiguration.defaultServiceType ?? 'repair');
      }
      if (!getValues('serviceType')) setValue('serviceType', 'repair');
      updateLocalStorage(value, field);
      forceUpdate();
    },
    [forceUpdate, getValues, setValue, formSteps],
  );
  const renderStepContent = () => {
    if (getValues('serviceType') === 'repair') {
      switch (activeStep) {
        case FORM_STEPS_ENUM.warrantyCheck:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <WarrantyCheck
                handleChange={handleChange}
                getValues={getValues}
                errors={errors}
                brandDetails={brandDetails}
                updateFormSteps={updateFormSteps}
                localStorageFormData={JSON.parse(localStorage.getItem('formData') || '{}')}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.contactDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <ContactDetails
                getValues={getValues}
                handleChange={handleChange}
                errors={errors}
                setLoading={setLoading}
                brandDetails={brandDetails}
                localStorageFormData={JSON.parse(localStorage.getItem('formData') || '{}')}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.productDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <ProductDetails
                productForm={productForm}
                getValues={getValues}
                handleChange={handleChange}
                errors={errors}
                brandDetails={brandDetails}
                localStorageFormData={JSON.parse(localStorage.getItem('formData') || '{}')}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.damageDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <DamageDetails
                getValues={getValues}
                handleChange={handleChange}
                setLoading={setLoading}
                brandDetails={brandDetails}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.pickupDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <PickUpDetails
                getValues={getValues}
                handleChange={handleChange}
                setLoading={setLoading}
                brandDetails={brandDetails}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.summary:
          return getValues('damageDetails')?.length === 0 ||
            JSON.stringify(getValues('damageDetails')) === JSON.stringify([{}]) ? (
            <Error displayMessage="No Damage Selected: (Choose a Damage to proceed ahead)" />
          ) : (
            <React.Suspense fallback={<Error displayMessage="Generating the Summary for You." />}>
              <Summary getValues={getValues} handleChange={handleChange} brandDetails={brandDetails} />
            </React.Suspense>
          );
        default:
          return <Text text={messages.common.notFound} className={`${brandDetails.brandName}-description`} />;
      }
    } else {
      switch (activeStep) {
        case FORM_STEPS_ENUM.warrantyCheck:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <WarrantyCheck
                handleChange={handleChange}
                getValues={getValues}
                errors={errors}
                brandDetails={brandDetails}
                updateFormSteps={updateFormSteps}
                localStorageFormData={JSON.parse(localStorage.getItem('formData') || '{}')}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.documentationCheck:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <DocumentationCheck
                getValues={getValues}
                handleChange={handleChange}
                errors={errors}
                brandDetails={brandDetails}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.contactDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <ContactDetails
                getValues={getValues}
                handleChange={handleChange}
                errors={errors}
                setLoading={setLoading}
                brandDetails={brandDetails}
                localStorageFormData={JSON.parse(localStorage.getItem('formData') || '{}')}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.productDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <ProductDetails
                productForm={productForm}
                getValues={getValues}
                handleChange={handleChange}
                errors={errors}
                brandDetails={brandDetails}
                localStorageFormData={JSON.parse(localStorage.getItem('formData') || '{}')}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.damageDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <DamageDetails
                getValues={getValues}
                handleChange={handleChange}
                setLoading={setLoading}
                brandDetails={brandDetails}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.pickupDetails:
          return (
            <React.Suspense fallback={<div>Loading...</div>}>
              <PickUpDetails
                getValues={getValues}
                handleChange={handleChange}
                setLoading={setLoading}
                brandDetails={brandDetails}
              />
            </React.Suspense>
          );
        case FORM_STEPS_ENUM.summary:
          return getValues('damageDetails')?.length === 0 ||
            JSON.stringify(getValues('damageDetails')) === JSON.stringify([{}]) ? (
            <Error displayMessage="No Damage Selected: (Choose a Damage to proceed ahead)" />
          ) : (
            <React.Suspense fallback={<Error displayMessage="Generating the Summary for You." />}>
              <Summary getValues={getValues} handleChange={handleChange} brandDetails={brandDetails} />
            </React.Suspense>
          );
        default:
          return <Text text={messages.common.notFound} />;
      }
    }
  };

  const sendCreatePaymentIntentDetails = React.useCallback(
    async (orderID: number, amountPaid: any, shippingCost: any) => {
      const args = {
        orderID,
        amountPaid: amountPaid,
        shippingCost: shippingCost,
        paymentMethodType: 'card',
        currency: 'nok',
      };
      if (amountPaid === 0) {
        navigate({
          pathname: '/order-summary',
          search: createSearchParams({ OrderID: orderID.toString(), success: 'true' }).toString(),
        });
      } else {
        await createStripePaymentIntent(apiKey, args).then((resp) => {
          if (!resp.error) {
            navigate({ pathname: '/checkout', search: createSearchParams({ orderID: orderID.toString() }).toString() });
          }
        });
      }
    },
    [],
  );

  //PENDING: error handling
  const createDraftOrder = React.useCallback(async () => {
    setLoading(true);
    const args = formatFormArgs(getValues());
    await createOrder(apiKey, args)
      .then(async (resp) => {
        if (resp.orderID) {
          if (getValues('receipt')) {
            await uploadFileToBlob(apiKey, 'receipts', resp.orderID, getValues('receipt'));
          }
          if (getValues('damagedProductImages').length) {
            const images = getValues('damagedProductImages');
            images.forEach((image) => {
              uploadFileToBlob(apiKey, 'damaged-product-images', resp.orderID, image);
            });
          }
          //await getCheckoutSessionDetails(resp.orderID, getValues('damageDetails'))
          await sendCreatePaymentIntentDetails(resp.orderID, args.amountPaid, args.shippingCost);
        }
      })
      .catch((error) => {
        console.log('Processing Error', error);
        if (error.response.data.orderID === 0) {
          setErrorOnSubmission(error.response.data.description);
        }
      })
      .finally(() => {
        setLoading(false);
        // setErrorOnSubmission('');
      });
  }, [getValues]);

  const onBackClick = React.useCallback(() => {
    const formStepIndex = formSteps.indexOf(activeStep);
    if (formStepIndex === 0) {
      navigate('/');
    }
    if (activeStep === FORM_STEPS_ENUM.productDetails && productForm === 'type') {
      setProductForm('info');
      return;
    }
    // if (activeStep === 'Contact Details' && contactForm === 'pickup') {
    //   setContactForm('info');
    //   return;
    // }
    setActiveStep(formSteps[formStepIndex - 1]);

    if (formSteps[formStepIndex - 1] === FORM_STEPS_ENUM.productDetails) {
      if (!!lineItemDetails) {
        setActiveStep(formSteps[formStepIndex - 2]);
      }
      return;
    }
  }, [activeStep, navigate, productForm, contactForm, formSteps]);

  const onNextClick = React.useCallback(() => {
    if (activeStep === FORM_STEPS_ENUM.damageDetails) {
      let arr = getValues('damageDetails');
      for (let i = 0; i < arr.length; i++) {
        if (JSON.stringify(getValues(`damageDetails`)[i]) === '{}') {
          return;
        }
      }
    }
    if (activeStep === FORM_STEPS_ENUM.summary) {
      createDraftOrder();
      return;
    }

    const formStepIndex = formSteps.indexOf(activeStep);
    if (activeStep === FORM_STEPS_ENUM.productDetails && productForm === 'info') {
      setProductForm('type');
      return;
    }

    if (activeStep === FORM_STEPS_ENUM.pickupDetails) {
      if (getValues('logisticsData').includes('HOME_PICK_UP') && getValues('pickUpDate') === '') {
        return;
      }
    }
    setActiveStep(formSteps[formStepIndex + 1]);
    if (formSteps[formStepIndex + 1] === FORM_STEPS_ENUM.productDetails) {
      if (!!lineItemDetails) {
        setActiveStep(formSteps[formStepIndex + 2]);
      }
      return;
    }
  }, [activeStep, productForm, formSteps, createDraftOrder, getValues]);

  const updateFormSteps = () => {
    list = getFormStepsNames(getValues('serviceType'), brandConfiguration);
    keysList = getFormStepKeys(getValues('serviceType'), brandConfiguration);
    setFormSteps(list);
    setFormStepsKeys(list);
  };

  React.useEffect(() => {
    if (getValues('serviceType') === 'warranty') {
      updateFormSteps();
    }
    setActiveSchema(schema(activeStep, productForm, contactForm));
    if (localStorage.getItem('formData') === '' || !localStorage.getItem('formData')) {
      localStorage.setItem('formData', JSON.stringify(getValues()));
    }
  }, [activeStep, productForm, contactForm, getValues]);

  return (
    <form onSubmit={handleSubmit(onNextClick)} className="order-booking-form">
      {brandDetails && brandConfiguration && (
        <>
          <Text text={messages.repairBookingForm.header} className={`${brandDetails?.brandName}-heading`} />
          <Stepper
            className="form-stepper Desk"
            steps={formSteps.map((key, index) => {
              return formStepsKeys[index];
            })}
            currentStep={formSteps.indexOf(activeStep)}
          />
          <StepperMob
            className="form-stepper Mob"
            steps={formSteps.map((key, index) => {
              return formStepsKeys[index];
            })}
            currentStep={formSteps.indexOf(activeStep)}
          />
          <div className="form-container">
            <div className="form-steps">{renderStepContent()}</div>
          </div>
          <React.Suspense fallback="">
            <FormFooter
              onBackClick={onBackClick}
              showSubmit={activeStep === 'Summary'}
              loading={loading}
              className={`${brandDetails.brandName}-font`}
            />
          </React.Suspense>
          {errorOnSubmission.length > 0 && (
            <Text text={` ---${errorOnSubmission}--- `} className="error discount-error" />
          )}
        </>
      )}
      {loading && <PageLoader className="overlay" />}
    </form>
  );
};
