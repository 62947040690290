import React from 'react';
import { ApiKeyContext, BrandsListContext } from '../..';
import { PageLoader } from '../../components/UI/PageLoader';
import { useSearchParams } from 'react-router-dom';
import './checkout.scss';
import { getDiscountCodeDetails, getOrderDetails } from '../../utils/api';
import { Text } from '../../components/UI/Text';
import ErrorIcon from '@mui/icons-material/Error';
import { BrandContext } from '../../App';
import { messages } from '../../Messages';
import { RepairDetailData } from '../../types/order/order.model';
import { CheckoutShippingInfo } from './checkoutShippingInfo';
import { CheckoutCardAndPaymentInfo } from './checkoutCardAndPaymentInfo';

export const Checkout: React.FC = () => {
  const brandDetails = React.useContext(BrandContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [searchParams] = useSearchParams();
  const [orderData, setOrderData] = React.useState<RepairDetailData>({} as RepairDetailData);
  let underWarranty = false;
  let coveredUnderWarranty = 0;
  let apiKey = React.useContext(ApiKeyContext);
  const [discountedAmount, setDiscountedAmount] = React.useState(0);

  const brandsList = React.useContext(BrandsListContext);

  // console.log('BrandDetails: ', brandsList);
  let brandID = brandsList.find(
    (brand) => brand.brandName.toUpperCase() === brandDetails?.brandName.toUpperCase(),
  )?.brandId;

  orderData.damageDetails?.map((damage: any) => {
    if (damage.isUnderWarranty) {
      coveredUnderWarranty += damage.amount;
      underWarranty = true;
    }
  });

  const calculateDiscountedAmount = React.useCallback(
    async (fetchedOrderData: RepairDetailData) => {
      setLoading(true);
      if (!!fetchedOrderData.discountCode) {
        await getDiscountCodeDetails(fetchedOrderData.discountCode, brandID ?? 6)
          .then((resp) => {
            let calculatedDiscount = 0;
            if (!!resp) {
              if (resp.discountPercentage !== 0) {
                calculatedDiscount = (resp.discountPercentage * fetchedOrderData.totalCost) / 100;
                if (resp.maxDiscountAmount > 0) {
                  calculatedDiscount = Math.min(calculatedDiscount, resp.maxDiscountAmount);
                }
              } else {
                calculatedDiscount = resp.discountAmount;
              }
              setDiscountedAmount(calculatedDiscount);
            } else {
              setDiscountedAmount(0);
            }
          })
          .catch((error) => {
            console.log('Failed to fetch the Discount Code');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [getDiscountCodeDetails],
  );
  // const { getValues } = useForm<FormDataType>();

  const fetchOrderDetails = React.useCallback(async (ID: number) => {
    await getOrderDetails(apiKey, ID)
      .then(async (data) => {
        setOrderData(data);
        await calculateDiscountedAmount(data);
      })
      .catch((e) => {
        console.log('Info Error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const ID = Number(searchParams.get('orderID'));
    if (ID) {
      fetchOrderDetails(ID);
    } else {
      setLoading(false);
      setError('error');
    }
  }, [searchParams, calculateDiscountedAmount]);

  return loading ? (
    <PageLoader content="Loading payment page. Please do not reload or refresh." />
  ) : (
    <>
      {error ? (
        <div className="error-info">
          <ErrorIcon />
          <Text text="Some error occured." className={`${brandDetails.brandName}-description-bold`} />
        </div>
      ) : (
        <>
          <div className="checkout-container">
            <div className="left-container">
              {/* <Text text="Pay Repair Community As" className="description" /> */}
              <Text
                text={`${orderData.currencyCode} ${orderData.amountPaid}`}
                className={`${brandDetails.brandName}-sub-heading`}
              />
              <div className="cost-summary">
                {orderData?.damageDetails?.map((damage: any, index: number) => (
                  <div className="cost" key={index}>
                    <Text text={damage.damageType} className={`${brandDetails.brandName}-description bold`} />
                    <Text
                      text={`${orderData.currencyCode} ${damage.amount}`}
                      className={`${brandDetails.brandName}-description bold`}
                    />
                  </div>
                ))}
                <CheckoutShippingInfo orderData={orderData} underWarranty={underWarranty} brandDetails={brandDetails} />

                {orderData.serviceType === 'warranty' && underWarranty && !orderData.receiptAvailable && (
                  <div className="cost">
                    <Text
                      text={messages.repairBookingForm.summary.underWarranty}
                      className={`${brandDetails.brandName}-description-small text light-text`}
                    />
                    <Text
                      text={`-${orderData.currencyCode || ''} ${coveredUnderWarranty}`}
                      className={`${brandDetails.brandName}-description-small light-text text`}
                    />
                  </div>
                )}
                {!!orderData.discountCode && (
                  <div className="cost">
                    <Text
                      text={orderData.discountCode}
                      className={`${brandDetails.brandName}-description bold accent`}
                    />
                    <Text
                      text={`- ${orderData.currencyCode} ${discountedAmount}`}
                      className={`${brandDetails.brandName}-description bold accent`}
                    />
                  </div>
                )}
                <hr />
                <div>
                  <div className="cost">
                    <Text text="Total due" className={`${brandDetails.brandName}-description-bold bold`} />
                    <Text
                      text={`${orderData.currencyCode} ${orderData.amountPaid?.toFixed(2)}`}
                      className={`${brandDetails.brandName}-description-bold bold`}
                    />
                  </div>
                  <Text
                    text="*inclusive of all taxes"
                    className={`${brandDetails.brandName}-description-small tax-description`}
                  />
                </div>
              </div>
            </div>
            <div className="right-container">
              <CheckoutCardAndPaymentInfo brandDetails={brandDetails} orderData={orderData} apiKey={apiKey} />
            </div>
          </div>
          <div className="footer">
            <div className="stripe-badge"></div>
          </div>
        </>
      )}
    </>
  );
};
