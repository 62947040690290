import { FormDataType, OrderStatus } from './constants';
import { PaymentStatusEnum } from '../../types/enum/order.enum';
import { BrandConfigurationModel } from '../../types/brand/brand.model';

export const formatFormArgs = (data: any) => {
  return {
    serviceType: data.serviceType,
    receiptAvailable: data.receiptAvailable,
    returnProduct: data.returnProduct,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    address: `${data.streetName},${data.streetNumber}`,
    postalCode: data.postalCode,
    city: data.city,
    country: data.country,
    modelName: data.modelName,
    modelNumber: data.modelNumber,
    productionCode: data.productionCode,
    isLabelsMissing: data.isLabelsMissing,
    productCategory: data.productCategory,
    totalCost: data.totalCost,
    currencyCode: data.damageDetails?.[0].currencyCode,
    comments: data.comments,
    damageDetails: data.damageDetails,
    status: OrderStatus.DRAFT,
    paymentStatus: PaymentStatusEnum.PENDING,
    amountPaid: data.amountPaid,
    repairerId: data.repairerId,
    shippingCost: data.shippingCost,
    returnShippingCost: data.returnShippingCost,
    logisticsData: data.logisticsData,
    discountCode: data.discountCode,
    integrationID: data.integrationID,
  };
};

export const calculateCost = (getValues: any, underWarranty: boolean) => {
  let totalCost = 0;
  let amountPaid = 0;
  getValues('damageDetails').map((damage: any, index: number) => {
    totalCost += damage.amount;
    if (
      !getValues(`damageDetails[${index}].isUnderWarranty`) ||
      getValues('serviceType') === 'repair' ||
      getValues('receiptAvailable')
    ) {
      amountPaid += damage.amount;
    }
  });
  if (!underWarranty) {
    amountPaid = totalCost;
  }
  return { totalCost, amountPaid };
};

export const getFormStepsNames = (serviceType: string, brandConfig: BrandConfigurationModel) => {
  let formStepsList: Array<string> = [];
  if (serviceType == 'warranty') {
    brandConfig.warrantyFormSteps.map((warrantytFormStep) => {
      formStepsList[Number(warrantytFormStep.stepIndex)] = warrantytFormStep.stepName;
    });
  }
  if (serviceType == 'repair') {
    brandConfig.repairFormSteps.map((repairFormStep) => {
      formStepsList[Number(repairFormStep.stepIndex)] = repairFormStep.stepName;
    });
  }
  return formStepsList;
};

export const getFormStepKeys = (serviceType: string, brandConfig: BrandConfigurationModel) => {
  let formStepsKeysList: Array<string> = [];
  if (serviceType == 'warranty') {
    brandConfig.warrantyFormSteps.map((warrantytFormStep) => {
      formStepsKeysList[Number(warrantytFormStep.stepIndex)] = warrantytFormStep.formStepKey;
    });
  }
  if (serviceType == 'repair') {
    brandConfig.repairFormSteps.map((repairFormStep) => {
      formStepsKeysList[Number(repairFormStep.stepIndex)] = repairFormStep.formStepKey;
    });
  }
  return formStepsKeysList;
};

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

export const getInitialInFocusList = (len: number) => {
  let inFocusList = [];
  for (let i = 0; i < len; i++) {
    inFocusList.push({ key: i, value: false });
  }
  return inFocusList;
};

//--------------------------
//--------------------------

export const updateFromLocalStorage = (formData: FormDataType, handleChange: any) => {
  handleChange(formData?.serviceType, 'serviceType');

  //

  handleChange(formData?.receiptAvailable, 'receiptAvailable');
  handleChange(formData?.receipt, 'receiptAvailable');
  handleChange(formData?.returnProduct, 'returnProduct');

  //

  handleChange(formData?.firstName, 'firstName');
  handleChange(formData?.lastName, 'lastName');
  handleChange(formData?.email, 'email');
  handleChange(formData?.phoneNumber, 'phoneNumber');
  handleChange(formData?.streetName, 'streetName');
  handleChange(formData?.postalCode, 'postalCode');
  handleChange(formData?.city, 'city');
  handleChange(formData?.country, 'country');

  //

  handleChange(formData?.isLabelsMissing, 'isLabelsMissing');
  handleChange(formData?.modelName, 'modelName');
  handleChange(formData?.modelNumber, 'modelNumber');
  handleChange(formData?.productionCode, 'productionCode');

  //

  handleChange(formData?.productCategory, 'productCategory');
  handleChange(formData?.productSubCategory, 'productSubCategory');

  //

  handleChange(formData?.damageDetails, 'damageDetails');

  //

  handleChange(formData?.logisticsData, 'logisticsData');
  handleChange(formData?.pickUpDate, 'pickUpDate');
  handleChange(formData?.pickUpTime, 'pickUpTime');
};
