import { messages } from '../../Messages';

export const FORM_STEPS_KEYS = [
  `${messages.repairBookingForm.warrantyCheck.header}`,
  `${messages.repairBookingForm.documentationCheck.header}`,
  `${messages.repairBookingForm.contactDetails.header}`,
  `${messages.repairBookingForm.productDetails.header}`,
  `${messages.repairBookingForm.damageDetails.header}`,
  `${messages.repairBookingForm.pickupDetails.header}`,
  `${messages.repairBookingForm.summary.header}`,
];
export const FORM_STEPS = [
  'Warranty Check',
  'Documentation Check',
  'Contact Details',
  'Product Details',
  'Damage Details',
  'Pick-Up Details',
  'Summary',
];

export enum FORM_STEPS_ENUM {
  warrantyCheck = 'Warranty Check',
  documentationCheck = 'Documentation Check',
  contactDetails = 'Contact Details',
  productDetails = 'Product Details',
  damageDetails = 'Damage Details',
  pickupDetails = 'Pick-Up Details',
  summary = 'Summary',
}

export type FormDataType = {
  serviceType: string;
  receipt: any;
  receiptAvailable: boolean;
  returnProduct: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  streetName: string;
  streetNumber?: string;
  postalCode: string;
  city: string;
  country: string;
  modelName?: string;
  modelNumber?: string;
  productionCode?: string;
  isLabelsMissing: boolean;
  productCategory: string;
  productSubCategory: string;
  categoryId: number;
  subCategoryId: number;
  damageDetails: Array<any>;
  comments: string;
  damagedProductImages: Array<any>;
  dropLocation: string;
  productUnderWarranty: boolean;
  totalCost: number;
  amountPaid: number;
  productInfo: boolean;
  repairerId: number;
  shippingCost: string;
  coveredUnderWarranty: number;
  pickUpDate: string;
  pickUpTime: string;
  logisticsData: string;
  discountCode: string;
  discountedAmount: number;
  integrationID: string;
};

export const formDefaultValues = {
  serviceType: '',
  receipt: '',
  receiptAvailable: false,
  returnProduct: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  streetName: '',
  streetNumber: '',
  postalCode: '',
  city: '',
  country: undefined,
  modelName: '',
  modelNumber: '',
  productionCode: '',
  isLabelsMissing: false,
  productCategory: '',
  productSubCategory: '',
  categoryId: 0,
  subCategoryId: 0,
  damageDetails: [],
  comments: '',
  damagedProductImages: [],
  totalCost: 0,
  amountPaid: 0,
  dropLocation: JSON.stringify({ name: '', id: '' }),
  productUnderWarranty: false,
  productInfo: false,
  repairerId: 0,
  shippingCost: '0',
  coveredUnderWarranty: 0,
  pickUpDate: '',
  pickUpTime: '',
  logisticsData: '',
  discountCode: '',
  discountedAmount: 0,
  integrationID: '',
};

export enum OrderStatus {
  DRAFT = 1,
  PLACED = 2,
}
