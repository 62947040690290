export const RepairableBrandDetails = () => {
  const RepairableBrandData = {
    homePageHeader: 'Order repairs by skilled repairers at fixed prices!',
    homePageDescription:
      'Revitalize your wardrobe with Repairable. Our expert clothing repair services bring new life to your favorite garments, ensuring they look and feel as good as new. Trust us to enhance the longevity and style of your cherished pieces',
    repairFormSteps: [
      { stepName: 'Contact Details', stepIndex: '0', formStepKey: 'contactDetails' },
      {
        stepName: 'Product Details',
        stepIndex: '1',
        formStepKey: 'productDetails',
      },
      { stepName: 'Damage Details', stepIndex: '2', formStepKey: 'damageDetails' },
      { stepName: 'Pick-Up Details', stepIndex: '3', formStepKey: 'pickupDetails' },
      { stepName: 'Summary', stepIndex: '4', formStepKey: 'summary' },
    ],
    warrantyFormSteps: [
      { stepName: 'Warranty Check', stepIndex: '0', formStepKey: 'warrantyCheck' },
      { stepName: 'Documentation Check', stepIndex: '1', formStepKey: 'documentationCheck' },
      { stepName: 'Contact Details', stepIndex: '2', formStepKey: 'contactDetails' },
      {
        stepName: 'Product Details',
        stepIndex: '3',
        formStepKey: 'productDetails',
      },
      { stepName: 'Damage Details', stepIndex: '4', formStepKey: 'damageDetails', preferredStores: [22, 7, 16] },
      { stepName: 'Pick-Up Details', stepIndex: '5', formStepKey: 'pickupDetails' },
      { stepName: 'Summary', stepIndex: '6', formStepKey: 'summary' },
    ],
    shopifyIntegrated: false,
    defaultServiceType: '',
  };
  return RepairableBrandData;
};

export const TingsConfiguration = () => {
  const tingsConfig = {
    repairFormSteps: [
      {
        stepName: 'Contact Details',
        stepIndex: '0',
        formStepKey: 'contactDetails',
      },
      // {
      //   stepName: 'Product Details',
      //   stepIndex: '1',
      //   formStepKey: 'productDetails',
      // },
      {
        stepName: 'Damage Details',
        stepIndex: '1',
        formStepKey: 'damageDetails',
        preferredStores: [22, 7, 16],
      },
      {
        stepName: 'Pick-Up Details',
        stepIndex: '2',
        formStepKey: 'pickupDetails',
      },
      {
        stepName: 'Summary',
        stepIndex: '3',
        formStepKey: 'summary',
      },
    ],
    defaultServiceType: 'repair',
  };
};

export type TingsPayload = () => {
  solutionId: string;
  receipt: {
    purchaseOrderNumber: string;
    orderDate: Date;
    totalPrice: number;
    retailerName: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
    phoneCountryCode: string;
    phoneNumber: string;
    email: string; // required for repairable
    addressStreetLine1: string;
    addressStreetLine2: string;
    addressStreetLine3: string;
    // e.g. "Per Post"
    addressName1: string;
    // e.g. "c/o Linda Letter"
    addressName2: string;
    addressCity: string;
    addressPostalCode: string;
    addressRegion: string;
    // ISO 3166-1 alpha-2 country code
    addressCountry: string;
  };
  // the case the user has reported, e.g. 'my jacket has a hole in the elbow'
  case: {
    id: string;
    status: string;
    title: string;
    helpRequest: string;
    images: { url: string }[];
    // warrantyClaimAssessment: LIKELY | UNLIKELY | MISSING_DATA | UNCERTAIN
    helpResponse: string;
    userDescription: string;
    procuctName: string;
    productMpn: string;
    productGtin: string;
    productDescription: string;
    productImage: string;
    productBrand: string;
    productColor: string;
    productSize: string;
  };
  // the repairable service, as represented in our system, and to the end user
  providerService: {
    // use dashboard to view and edit details (and API in the future)
    id: string;
    categories: [];
  };
};
